@tailwind base;
@tailwind components;
@tailwind utilities;

button {
    @apply px-3 py-2 text-sm font-bold text-white bg-white rounded-md font-action md:text-base lg:text-lg bg-opacity-10 hover:bg-opacity-20 active:bg-opacity-25 focus:bg-opacity-20 focus:ring-2 ring-blue-500;
}

.choice-button {
  @apply bg-lime-400 text-black hover:bg-white hover:text-lime-800;
}

.repeated-choice-text {
  @apply text-lime-400;
}

.game-img {
    /* @apply object-contain w-full h-fit absolute top-0 left-0; */
    @apply object-scale-down w-full absolute top-0 left-0 md:max-h-[95vh];
    filter: drop-shadow(0 0 0 transparent);
    transition: filter ease-in-out 2s;
}

.current-img {
  filter: drop-shadow(1px 1px 9px #b4af2f);
}

.fire-animation {
  animation: fireloop 10s linear infinite normal both;
  opacity: 0.5;
}

@keyframes fireloop {
  0% {
    opacity: 0.5
  }

  30% {
    opacity: 0.6;
  }

  50% {
    opacity: 0.37
  }

  60% {
    opacity: 0.55;
  }

  75% {
    opacity: 0.43;
  }

  80% {
    opacity: 0.6;
  }

  100% {
    opacity: 0.5
  }
}

.chat-bubble .dialog {
    @apply p-3;
    background-color: #533659;
    border-radius: 8px;
}

/* .chat-bubble .character {
    @apply flex items-end space-x-4;
    margin-top: -8px;    
} */

.chat-bubble .avatar {
    background-color: rgb(195, 195, 195);
    border: 3px solid #816088;
    border-radius: 50%;
    height: auto;
    margin-top: -8px;
    width: 48px;
}

/* .chat-bubble .character .name {
    @apply font-sans text-sm font-bold;
} */

nav ul li a,
nav ul li button
 {
    @apply inline-block text-lg font-bold text-white bg-white bg-opacity-0 rounded-md hover:bg-opacity-10 active:bg-opacity-20 font-action;
    padding: 8px;
}

nav ul li .spacer {
    border-color: hsl(0, 0%, 27%);
    border-width: 1px;
    height: 100%;
}

/* TODO: surely there's a better way to hide scroll bars now? */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

@font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
         url('../static/font/dm-sans-v11-latin-regular.woff2') format('woff2'), 
         url('../static/font/dm-sans-v11-latin-regular.woff') format('woff'); 
  }
  
  @font-face {
    font-family: 'DM Sans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local(''),
         url('../static/font/dm-sans-v11-latin-italic.woff2') format('woff2'), 
         url('../static/font/dm-sans-v11-latin-italic.woff') format('woff'); 
  }
  
  @font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''),
         url('../static/font/dm-sans-v11-latin-700.woff2') format('woff2'), 
         url('../static/font/dm-sans-v11-latin-700.woff') format('woff'); 
  }

/* epilogue-regular - latin */
@font-face {
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
         url('../static/font/epilogue-v13-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../static/font/epilogue-v13-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  
  /* epilogue-700 - latin */
  @font-face {
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''),
         url('../static/font/epilogue-v13-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../static/font/epilogue-v13-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  
  /* epilogue-italic - latin */
  @font-face {
    font-family: 'Epilogue';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local(''),
         url('../static/font/epilogue-v13-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../static/font/epilogue-v13-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  
  /* epilogue-700italic - latin */
  @font-face {
    font-family: 'Epilogue';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local(''),
         url('../static/font/epilogue-v13-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../static/font/epilogue-v13-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  
  
@font-face {
    font-family: 'Grandstander';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
         url('../static/font/grandstander-v11-latin-regular.woff2') format('woff2'), 
         url('../static/font/grandstander-v11-latin-regular.woff') format('woff'); 
  }
  
  @font-face {
    font-family: 'Grandstander';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''),
         url('../static/font/grandstander-v11-latin-700.woff2') format('woff2'),
         url('../static/font/grandstander-v11-latin-700.woff') format('woff'); 
  }
  
  @font-face {
    font-family: 'Grandstander';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local(''),
         url('../static/font/grandstander-v11-latin-800.woff2') format('woff2'), 
         url('../static/font/grandstander-v11-latin-800.woff') format('woff'); 
  }
  

  /* ---------------- Credits ---------------- */
  body.credits {
    margin: 0 auto;
    padding: 0 1rem;
  }

  .credits .content-area {
    margin: 0 auto;
    max-width: 48rem;
  }

  #credits {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr;
  }

  @media screen and (min-width:540px) {
    #credits {
        grid-template-columns: 1fr 1fr;
    }
  }

  .credits .person {
    margin: 2rem 0;
  }

  .credits .person span {
    @apply text-2xl font-bold;
  }

  .credits .person ul {
    list-style-position: inside;
    list-style-type: disc;
  }

  .credits .person li {
    opacity: 0.8;
  }

  .credits .link {
    @apply underline text-lime-400 hover:bg-lime-300 hover:text-black;
  }
