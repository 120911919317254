*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-family: DM Sans, Helvetica Neue, Helvetica, Roboto, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.pointer-events-none {
  pointer-events: none;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.top-1\/3 {
  top: 33.3333%;
}

.left-1\/3 {
  left: 33.3333%;
}

.z-50 {
  z-index: 50;
}

.z-40 {
  z-index: 40;
}

.m-auto {
  margin: auto;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.box-border {
  box-sizing: border-box;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.h-screen {
  height: 100vh;
}

.h-\[50vh\] {
  height: 50vh;
}

.h-full {
  height: 100%;
}

.w-screen {
  width: 100vw;
}

.w-3\/4 {
  width: 75%;
}

.w-full {
  width: 100%;
}

.min-w-\[310px\] {
  min-width: 310px;
}

.max-w-3xl {
  max-width: 48rem;
}

.grow-0 {
  flex-grow: 0;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.content-center {
  align-content: center;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.gap-8 {
  gap: 2rem;
}

.gap-2 {
  gap: .5rem;
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.bg-surface-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 15 24 / var(--tw-bg-opacity));
}

.bg-lime-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(63 98 18 / var(--tw-bg-opacity));
}

.bg-surface-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 24 38 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-opacity-70 {
  --tw-bg-opacity: .7;
}

.bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}

.from-surface-800 {
  --tw-gradient-from: #1e1826;
  --tw-gradient-to: #1e182600;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-surface-900 {
  --tw-gradient-to: #160f18;
}

.object-contain {
  object-fit: contain;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.p-3 {
  padding: .75rem;
}

.p-6 {
  padding: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.align-middle {
  vertical-align: middle;
}

.font-action {
  font-family: Grandstander, Helvetica Neue, Helvetica, Roboto, sans-serif;
}

.font-paper {
  font-family: Epilogue, Helvetica Neue, Helvetica, Roboto, sans-serif;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.font-bold {
  font-weight: 700;
}

.leading-relaxed {
  line-height: 1.625;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-white\/80 {
  color: #fffc;
}

.text-opacity-90 {
  --tw-text-opacity: .9;
}

.text-opacity-60 {
  --tw-text-opacity: .6;
}

button {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-bg-opacity: .1;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity));
  border-radius: .375rem;
  padding: .5rem .75rem;
  font-family: Grandstander, Helvetica Neue, Helvetica, Roboto, sans-serif;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.25rem;
}

button:hover {
  --tw-bg-opacity: .2;
}

button:focus {
  --tw-bg-opacity: .2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

button:active {
  --tw-bg-opacity: .25;
}

@media (min-width: 820px) {
  button {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 1024px) {
  button {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

.choice-button {
  --tw-bg-opacity: 1;
  background-color: rgb(163 230 53 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.choice-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(63 98 18 / var(--tw-text-opacity));
}

.repeated-choice-text {
  --tw-text-opacity: 1;
  color: rgb(163 230 53 / var(--tw-text-opacity));
}

.game-img {
  width: 100%;
  object-fit: scale-down;
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 820px) {
  .game-img {
    max-height: 95vh;
  }
}

.game-img {
  filter: drop-shadow(0 0 #0000);
  transition: filter 2s ease-in-out;
}

.current-img {
  filter: drop-shadow(1px 1px 9px #b4af2f);
}

.fire-animation {
  opacity: .5;
  animation: 10s linear infinite both fireloop;
}

@keyframes fireloop {
  0% {
    opacity: .5;
  }

  30% {
    opacity: .6;
  }

  50% {
    opacity: .37;
  }

  60% {
    opacity: .55;
  }

  75% {
    opacity: .43;
  }

  80% {
    opacity: .6;
  }

  100% {
    opacity: .5;
  }
}

.chat-bubble .dialog {
  background-color: #533659;
  border-radius: 8px;
  padding: .75rem;
}

.chat-bubble .avatar {
  height: auto;
  width: 48px;
  background-color: #c3c3c3;
  border: 3px solid #816088;
  border-radius: 50%;
  margin-top: -8px;
}

nav ul li a, nav ul li button {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-bg-opacity: 0;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: .375rem;
  font-family: Grandstander, Helvetica Neue, Helvetica, Roboto, sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.75rem;
  display: inline-block;
}

nav ul li a:hover, nav ul li button:hover {
  --tw-bg-opacity: .1;
}

nav ul li a:active, nav ul li button:active {
  --tw-bg-opacity: .2;
}

nav ul li a, nav ul li button {
  padding: 8px;
}

nav ul li .spacer {
  height: 100%;
  border-width: 1px;
  border-color: #454545;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@font-face {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(""), url("dm-sans-v11-latin-regular.d57eca11.woff2") format("woff2"), url("dm-sans-v11-latin-regular.760f436f.woff") format("woff");
}

@font-face {
  font-family: DM Sans;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local(""), url("dm-sans-v11-latin-italic.6f9165db.woff2") format("woff2"), url("dm-sans-v11-latin-italic.f3063588.woff") format("woff");
}

@font-face {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(""), url("dm-sans-v11-latin-700.961bd654.woff2") format("woff2"), url("dm-sans-v11-latin-700.b29ca852.woff") format("woff");
}

@font-face {
  font-family: Epilogue;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(""), url("epilogue-v13-latin-regular.cda34fa0.woff2") format("woff2"), url("epilogue-v13-latin-regular.10406415.woff") format("woff");
}

@font-face {
  font-family: Epilogue;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(""), url("epilogue-v13-latin-700.d70dd026.woff2") format("woff2"), url("epilogue-v13-latin-700.4970f949.woff") format("woff");
}

@font-face {
  font-family: Epilogue;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local(""), url("epilogue-v13-latin-italic.8d20b54d.woff2") format("woff2"), url("epilogue-v13-latin-italic.afc828b3.woff") format("woff");
}

@font-face {
  font-family: Epilogue;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local(""), url("epilogue-v13-latin-700italic.47a82257.woff2") format("woff2"), url("epilogue-v13-latin-700italic.42348777.woff") format("woff");
}

@font-face {
  font-family: Grandstander;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(""), url("grandstander-v11-latin-regular.2ff5cc72.woff2") format("woff2"), url("grandstander-v11-latin-regular.258a4ea8.woff") format("woff");
}

@font-face {
  font-family: Grandstander;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(""), url("grandstander-v11-latin-700.39db1d3b.woff2") format("woff2"), url("grandstander-v11-latin-700.f753d395.woff") format("woff");
}

@font-face {
  font-family: Grandstander;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local(""), url("grandstander-v11-latin-800.1adc9b32.woff2") format("woff2"), url("grandstander-v11-latin-800.d0277b73.woff") format("woff");
}

body.credits {
  margin: 0 auto;
  padding: 0 1rem;
}

.credits .content-area {
  max-width: 48rem;
  margin: 0 auto;
}

#credits {
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  display: grid;
}

@media screen and (min-width: 540px) {
  #credits {
    grid-template-columns: 1fr 1fr;
  }
}

.credits .person {
  margin: 2rem 0;
}

.credits .person span {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

.credits .person ul {
  list-style-type: disc;
  list-style-position: inside;
}

.credits .person li {
  opacity: .8;
}

.credits .link {
  --tw-text-opacity: 1;
  color: rgb(163 230 53 / var(--tw-text-opacity));
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.credits .link:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(190 242 100 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

@media (min-width: 640px) {
  .sm\:flex {
    display: flex;
  }

  .sm\:w-auto {
    width: auto;
  }
}

@media (min-width: 820px) {
  .md\:inline-block {
    display: inline-block;
  }

  .md\:h-screen {
    height: 100vh;
  }

  .md\:max-h-\[95vh\] {
    max-height: 95vh;
  }

  .md\:w-1\/3 {
    width: 33.3333%;
  }

  .md\:w-1\/4 {
    width: 25%;
  }

  .md\:max-w-\[420px\] {
    max-width: 420px;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:p-2 {
    padding: .5rem;
  }
}

@media (min-width: 1024px) {
  .lg\:p-6 {
    padding: 1.5rem;
  }
}

@media (min-width: 1280px) {
  .xl\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

/*# sourceMappingURL=index.0e0ddaaf.css.map */
